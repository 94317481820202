import React from "react"
import "./Navbar.css"
import Logo from "../../assets/images/logo_t.png"

const Navbar = ({ sticky }) => (
  /*<nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
    <div className="navbar--logo-holder">
      {sticky ? <img src={Logo} alt="logo" className="navbar--logo" /> : <img src={Logo} alt="logo" className="navbar--logo" />}
      <h1>EMC</h1>
    </div>
    <ul className="navbar--link">
      <li className="navbar--link-item">Home</li>
      <li className="navbar--link-item">About</li>
      <li className="navbar--link-item">Blog</li>
    </ul>
</nav> */

<nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
    <div className="navbar--logo-holder">
      {sticky ? <img src={Logo} alt="logo" className="navbar--logo" /> : <img src={Logo} alt="logo" className="navbar--logo" />}
      <h1>EMC</h1>
    </div>
    <ul className="navbar--link">
      <li className="navbar--link-item">Boring Kraken</li>
      <li className="navbar--link-item">ShortimusPrim3</li>
      <li className="navbar--link-item">DopeyBunyip</li>
    </ul> 
  </nav>


)

export default Navbar